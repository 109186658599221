import React from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router";
import ReactGA from "react-ga4";
import CustomButton from "../CustomButton";

const BlogSubcriptionSuccess = (
  phonewidth,
  isTabletVertical,
  isTabletHorizontal,
  isDesktop,
  xxlwidth
) => {
  const navigate = useNavigate();

  return (
    <>
      <Grid container mt={phonewidth ? '12%':"3%"}>
        <Grid xs={1} md={2} xl={2} />
        <Grid xs={11} md={4} xl={4}>
          <h1 style={design.title}>
            ¡Gracias por inscribirte a nuestro newsletter!
          </h1>
          <p style={design.subtitle}>
            Una guía trimestral con los contenidos más interesantes para cuidar
            tu salud y sentirte bien. Hablamos de avances en medicina, consejos
            de expertos, tratamientos, tecnología en salud y mucho más...
          </p>

          <CustomButton
            id="Newsletter-subscription-button"
            title="Newsletter-button-home"
            name="Newsletter-button-home"
            variant="outlined"
            onClick={() => {
              // Event GA4
              ReactGA.event({
                category: "Interaccion",
                action:
                  "Visitar_sitio_web_landing_blog_subscription_agradecimiento",
                label: "Visitar nuestra web",
                value: 1,
              });
              navigate("/");
            }}
          >
            Visitar nuestra web
          </CustomButton>
        </Grid>
        <Grid xs={12} md={4} xl={4} style={design.img}>
          <Box
            component="img"
            src="https://movicaremx.com/IMG/Sitioweb/Mujer_Suscribirse.png"
            alt="Mujer_Suscribirse"
            title="Mujer_Suscribirse"
            height={"80%"}
            width={"80%"}
          />
        </Grid>
        <Grid xs={0} md={2} xl={2} />
      </Grid>
    </>
  );
};
export default BlogSubcriptionSuccess;

const design = {
  title: {
    fontWeight: "bold",
    color: "#093046",
    textAlign: "left",
    paddingLeft: "2%",
    fontFamily: "Lexend",
  },
  subtitle: {
    textAlign: "left",
    padding: "0% 5% 0% 2%",
    color: "#093046",
    fontFamily: "Lexend",
  },
  img: {
    alignSelf: "center",
    textAlign: "center",
  },
};

import React from "react";
import { useTheme } from "@mui/material";
import { CssButtonOutlined } from "../CSS/CssButtonOutlined";
import { CssButtonContained } from "../CSS/CssButtonContained";

/**
 * CustomButton component to render the CssButton with theme support.
 *
 * @param {object} props - Component props.
 * @param {string} props.type - The type of the button (e.g., "submit").
 * @param {boolean} props.fullWidth - If true, the button will take the full width of its container.
 * @param {string} props.variant - The variant of the button (e.g., "contained").
 * @param {string} props.color - The color of the button.
 * @param {object} props.sx - The style overrides for the button.
 * @param {React.ReactNode} props.children - The content of the button.
 * @returns {JSX.Element} Themed button component.
 */
export default function CustomButton({
  type,
  fullWidth,
  width,
  variant,
  color,
  colortexto,
  sx,
  children,
  bordercolor,
  backgroundcolor,
  fontSize,
  onClick,
  href,
  colortexthover,
  shadowcolorBox,
  ...props
}) {
  const theme = useTheme();

  // Define colors based on the theme
  const colortext = theme.palette.mode === "dark" ? "#fff" : "#000000";
  const boxshadowcolor =
    theme.palette.mode === "dark"
      ? "rgba(0, 0, 0, 0.5)"
      : shadowcolorBox;

  return (
    <>
      {variant === "outlined" ? (
        <CssButtonOutlined
          {...props}
          type={type}
          fullWidth={fullWidth}
          width={width}
          variant={variant}
          color={color}
          colortext={colortexto ? colortexto : colortext}
          bordercolor={bordercolor}
          boxshadowcolor={boxshadowcolor}
          backgroundcolor={backgroundcolor}
          colortexthover={colortexthover}
          fontSize={fontSize}
          sx={sx}
          onClick={onClick}
          href={href}
        >
          {children}
        </CssButtonOutlined>
      ) : (
        <CssButtonContained
          {...props}
          type={type}
          fullWidth={fullWidth}
          width={width}
          variant={variant}
          color={color}
          colortext={colortexto ? colortexto : colortext}
          bordercolor={bordercolor}
          boxshadowcolor={boxshadowcolor}
          backgroundcolor={backgroundcolor}
          colortexthover={colortexthover}
          fontSize={fontSize}
          sx={sx}
          onClick={onClick}
          href={href}
        >
          {children}
        </CssButtonContained>
      )}
    </>
  );
}
